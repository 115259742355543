<!-- <app-authentication (reloadHeader)="reloadInit()"></app-authentication> -->

<header class="mob-header flex space-between" [ngStyle]="{'background-color': GlobalConstants.data.colors.header_bg_color}">
  <section class="flex">
    <div class="client-logo flex align-center pointer" routerLink = "/">
      <img [src] = "GlobalConstants.data.logo.desktop_url" class="img-fluid" >
    </div>
  </section>
  <section class="flex" [ngStyle]="{'background-color': GlobalConstants.data.colors.main_bg_color}">
    <div class="flex align-center justify-center search-cta pointer" (click)="searchModal.show()">
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.31212 12.6214C7.85695 12.6209 9.34739 12.0503 10.4984 11.0187L14.3667 14.8916C14.4364 14.9611 14.5307 15.0001 14.6291 15C14.7274 14.9999 14.8217 14.9608 14.8913 14.8911C14.9608 14.8215 14.9999 14.7271 15 14.6286C15.0001 14.5302 14.9612 14.4357 14.8918 14.366L11.0234 10.493C11.9792 9.41171 12.5357 8.03499 12.6001 6.59253C12.6645 5.15008 12.2329 3.72913 11.3773 2.56679C10.5217 1.40444 9.29387 0.570995 7.89866 0.205515C6.50345 -0.159966 5.02525 -0.0353763 3.7107 0.558495C2.39616 1.15237 1.32478 2.1796 0.675319 3.4688C0.025863 4.758 -0.162386 6.23119 0.141983 7.64256C0.446352 9.05393 1.22493 10.3181 2.34781 11.2242C3.47068 12.1303 4.86995 12.6234 6.31212 12.6214ZM6.31212 0.725638C7.41366 0.725638 8.49047 1.05267 9.40637 1.66539C10.3223 2.2781 11.0361 3.14897 11.4577 4.16788C11.8792 5.18678 11.9895 6.30796 11.7746 7.38962C11.5597 8.47128 11.0293 9.46485 10.2503 10.2447C9.47144 11.0245 8.47905 11.5556 7.39868 11.7708C6.3183 11.9859 5.19846 11.8755 4.18077 11.4534C3.16308 11.0314 2.29324 10.3167 1.68126 9.3997C1.06927 8.48271 0.742627 7.40462 0.742627 6.30177C0.744288 4.8234 1.33161 3.40605 2.37573 2.36069C3.41985 1.31532 4.83551 0.727302 6.31212 0.725638Z" fill="white"/>
      </svg>
      <span class="fnt-16 hide se mar-left-8 mar-top-1">Search</span>
    </div>
    <div class="flex justify-center align-center pointer" >
      <div class="cart w100 h100 flex justify-center align-center" *ngIf="isLoggedIn && (isGiftCardRoute || cartLength > 0)">
        <app-cart class="flex" [data]="cartData"  (apiStatus)="recievedApiStatus($event)" (cartResponse)="getGiftcardsInCart($event)" (initialCartResponse)="getGiftcardsInCart($event)"></app-cart>
      </div>
      <div class="cart w100 h100 flex justify-center align-center" *ngIf="!isLoggedIn">
        <span class="login-btn fnt-15"  (click) = "login()">Login</span>
      </div>
    </div>
    <div class="flex align-center justify-center profile-box-contain" *ngIf="isLoggedIn">
      <div class="dropdown select-dropdown flex justify-end align-center" dropdown>
        <div dropdownToggle class="menu-item account-item flex justify-end align-center profile-box pointer">
          <svg class="se-svg" width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.89252 4.58801C2.89252 7.12091 4.95418 9.17603 7.48053 9.17603C10.0069 9.17603 12.0685 7.11437 12.0685 4.58801C12.0685 2.06166 10.0069 0 7.48053 0C4.95418 0 2.89252 2.05512 2.89252 4.58801ZM11.5777 4.58801C11.5777 6.84602 9.73854 8.68515 7.48053 8.68515C5.22252 8.68515 3.38339 6.84602 3.38339 4.58801C3.38339 2.33 5.22252 0.490871 7.48053 0.490871C9.73854 0.490871 11.5777 2.33 11.5777 4.58801Z" fill="white"/>
            <path d="M14.6865 18.7512V16.539C14.6865 12.6775 11.5449 9.54242 7.68992 9.54242C3.83494 9.54242 0.693359 12.6775 0.693359 16.539V18.7512C0.693359 18.8886 0.804624 18.9999 0.942068 18.9999H14.4509C14.5818 18.9933 14.6865 18.8886 14.6865 18.7512ZM14.1956 18.5025H1.18423V16.5324C1.18423 12.9458 4.10328 10.0267 7.68992 10.0267C11.2766 10.0267 14.1956 12.9458 14.1956 16.5324V18.5025Z" fill="white"/>
          </svg>
          <svg class="down-arr mar-left-8 mar-top-2" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L5 5L9 1" stroke="#ccc" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="dropdown-menu w100 pad-7 profile-menu" *dropdownMenu>
          <div>
            <div class="added-card-info flex align-baseline pointer" [ngStyle]="{'background-color': card.currentCard ? GlobalConstants.data.colors.active_card_bg_color : '#fff'}" *ngFor="let card of userCards;" (click)="setCurrentUserCards(card)">
              <div>

                <svg class="visa-drop-ic" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.4495 0H1.78874C0.800508 0 0 0.800566 0 1.78874V9.82862C0 10.8167 0.800508 11.6173 1.78874 11.6173H13.4495C14.4372 11.6173 15.2381 10.8167 15.2381 9.82862V1.78874C15.2381 0.800566 14.4372 0 13.4495 0ZM1.78874 1.20685H13.4495C13.7702 1.20685 14.031 1.46813 14.031 1.78874V2.414H1.20685V1.78874C1.20685 1.46813 1.46784 1.20685 1.78874 1.20685ZM13.4495 10.4105H1.78874C1.46784 10.4105 1.20685 10.1492 1.20685 9.82862V4.52632H14.031V9.82862C14.031 10.1492 13.7702 10.4105 13.4495 10.4105Z" fill="#333333"/>
                <path d="M4.6587 8.43248H2.70752V8.97078H4.6587V8.43248Z" fill="#333333"/>
                <path d="M7.2826 8.43248H5.33142V8.97078H7.2826V8.43248Z" fill="#333333"/>
                <path d="M9.90687 8.43248H7.95569V8.97078H9.90687V8.43248Z" fill="#333333"/>
                <path d="M12.5306 8.43248H10.5795V8.97078H12.5306V8.43248Z" fill="#333333"/>
                </svg>
              </div>
              <div>
                <div class="visa-drop-num"> XXXX XXXX XXXX {{card.attributes.bin | slice:-4 }} </div>
                <div class="fnt-12">{{card?.attributes.display_name}}</div>
              </div>
            </div>
          </div>
          <div class="availed-offers-info pointer flex align-center" *ngIf = "GlobalConstants.data.personalized_config.redemption_page_needed">
            <svg xmlns="http://www.w3.org/2000/svg" class="reward-drop-ic" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 438.526 438.526" xml:space="preserve">
              <g>
                <path [ngStyle]="{'fill': GlobalConstants.data.colors.main_bg_color}" d="M435.969,157.878c-1.708-1.712-3.897-2.568-6.57-2.568H303.778c17.7,0,32.784-6.23,45.255-18.699   c12.464-12.468,18.699-27.552,18.699-45.254s-6.235-32.786-18.699-45.254c-12.471-12.465-27.555-18.699-45.255-18.699   c-20.365,0-36.356,7.327-47.965,21.982l-36.547,47.108l-36.54-47.108c-11.613-14.655-27.6-21.982-47.967-21.982   c-17.703,0-32.789,6.23-45.253,18.699c-12.465,12.468-18.7,27.552-18.7,45.254s6.231,32.79,18.7,45.254   c12.467,12.465,27.55,18.699,45.253,18.699H9.135c-2.667,0-4.854,0.856-6.567,2.568C0.857,159.593,0,161.783,0,164.446v91.367   c0,2.662,0.854,4.853,2.568,6.563c1.712,1.711,3.903,2.566,6.567,2.566h27.41v118.776c0,7.618,2.665,14.086,7.995,19.41   c5.326,5.332,11.798,7.994,19.414,7.994h310.629c7.618,0,14.086-2.662,19.417-7.994c5.325-5.328,7.995-11.799,7.995-19.41V264.942   h27.397c2.669,0,4.859-0.855,6.57-2.566s2.563-3.901,2.563-6.563v-91.367C438.529,161.783,437.68,159.593,435.969,157.878z    M284.081,72.798c4.948-5.898,11.512-8.848,19.697-8.848c7.618,0,14.089,2.662,19.418,7.992c5.324,5.327,7.987,11.8,7.987,19.414   s-2.67,14.087-7.987,19.414c-5.332,5.33-11.8,7.992-19.418,7.992h-55.391L284.081,72.798z M134.756,118.763   c-7.614,0-14.082-2.663-19.42-7.992c-5.327-5.327-7.993-11.8-7.993-19.414s2.663-14.084,7.993-19.414   c5.33-5.33,11.803-7.992,19.417-7.992c8.188,0,14.753,2.946,19.702,8.848l35.975,45.965H134.756z M264.951,210.128v133.62v15.985   c0,4.76-1.719,8.422-5.141,10.995c-3.429,2.57-7.809,3.856-13.131,3.856H191.86c-5.33,0-9.707-1.286-13.134-3.856   c-3.427-2.573-5.142-6.235-5.142-10.995v-15.985v-133.62V155.31h91.367V210.128z"/>
              </g>
            </svg>
            <a routerLink="/booking" class="visa-drop-num f500 c555 mar-top-5">Redemption History</a>
          </div>
          <div routerLink="/transactions" class="availed-offers-info pointer flex align-center" *ngIf = "GlobalConstants.data.personalized_config.redemption_page_needed">
            <!-- <svg class="transaction-icon mar-right-10 mar-top-2" xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
              <path d="M16.5 1.5L15 0L13.5 1.5L12 0L10.5 1.5L9 0L7.5 1.5L6 0L4.5 1.5L3 0V14H0V17C0 18.66 1.34 20 3 20H15C16.66 20 18 18.66 18 17V0L16.5 1.5ZM12 18H3C2.45 18 2 17.55 2 17V16H12V18ZM16 17C16 17.55 15.55 18 15 18C14.45 18 14 17.55 14 17V14H5V3H16V17Z" [ngStyle]="{'fill': GlobalConstants.data.colors.main_bg_color}"/>
              <path d="M12 5H6V7H12V5Z" [ngStyle]="{'fill': GlobalConstants.data.colors.main_bg_color}"/>
              <path d="M15 5H13V7H15V5Z" [ngStyle]="{'fill': GlobalConstants.data.colors.main_bg_color}"/>
              <path d="M12 8H6V10H12V8Z" [ngStyle]="{'fill': GlobalConstants.data.colors.main_bg_color}"/>
              <path d="M15 8H13V10H15V8Z" [ngStyle]="{'fill': GlobalConstants.data.colors.main_bg_color}"/>
            </svg> -->
            <svg class="reward-drop-ic" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_dddddd_1569_615)">
              <rect width="20" height="20" rx="10" transform="matrix(1 0 0 -1 0 20)" fill="#555A5B"/>
              </g>
              <path d="M10.4342 12.7342L13.6585 14.7827L12.8029 10.9218L15.6516 8.3241L11.9003 7.98909L10.4342 4.3479L8.9681 7.98909L5.2168 8.3241L8.06549 10.9218L7.20984 14.7827L10.4342 12.7342Z" fill="#FEFEFE"/>
              <defs>
              <filter id="filter0_dddddd_1569_615" x="-172" y="-176" width="364" height="364" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="-0.11069"/>
              <feGaussianBlur stdDeviation="2.37984"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.72549 0 0 0 0 0.729412 0 0 0 0 0.74902 0 0 0 0.0224916 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1569_615"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="-0.266004"/>
              <feGaussianBlur stdDeviation="5.71909"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.72549 0 0 0 0 0.729412 0 0 0 0 0.74902 0 0 0 0.0323114 0"/>
              <feBlend mode="normal" in2="effect1_dropShadow_1569_615" result="effect2_dropShadow_1569_615"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="-0.500862"/>
              <feGaussianBlur stdDeviation="10.7685"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.72549 0 0 0 0 0.729412 0 0 0 0 0.74902 0 0 0 0.04 0"/>
              <feBlend mode="normal" in2="effect2_dropShadow_1569_615" result="effect3_dropShadow_1569_615"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="-0.893452"/>
              <feGaussianBlur stdDeviation="19.2092"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.72549 0 0 0 0 0.729412 0 0 0 0 0.74902 0 0 0 0.0476886 0"/>
              <feBlend mode="normal" in2="effect3_dropShadow_1569_615" result="effect4_dropShadow_1569_615"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="-1.6711"/>
              <feGaussianBlur stdDeviation="35.9287"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.72549 0 0 0 0 0.729412 0 0 0 0 0.74902 0 0 0 0.0575083 0"/>
              <feBlend mode="normal" in2="effect4_dropShadow_1569_615" result="effect5_dropShadow_1569_615"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="-4"/>
              <feGaussianBlur stdDeviation="86"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.72549 0 0 0 0 0.729412 0 0 0 0 0.74902 0 0 0 0.08 0"/>
              <feBlend mode="normal" in2="effect5_dropShadow_1569_615" result="effect6_dropShadow_1569_615"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect6_dropShadow_1569_615" result="shape"/>
              </filter>
              </defs>
            </svg>
            <a class="visa-drop-num f500 c555 mar-top-5">White Pass History</a>
          </div>
          <div (click)="logout()" class="availed-offers-info pointer flex align-center">
            <svg class="reward-drop-ic" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <g>
                <path [ngStyle]="{'fill': GlobalConstants.data.colors.main_bg_color}" d="M255.15,468.625H63.787c-11.737,0-21.262-9.526-21.262-21.262V64.638c0-11.737,9.526-21.262,21.262-21.262H255.15    c11.758,0,21.262-9.504,21.262-21.262S266.908,0.85,255.15,0.85H63.787C28.619,0.85,0,29.47,0,64.638v382.724    c0,35.168,28.619,63.787,63.787,63.787H255.15c11.758,0,21.262-9.504,21.262-21.262    C276.412,478.129,266.908,468.625,255.15,468.625z"/>
            </g>
            <g>
                <path [ngStyle]="{'fill': GlobalConstants.data.colors.main_bg_color}" d="M505.664,240.861L376.388,113.286c-8.335-8.25-21.815-8.143-30.065,0.213s-8.165,21.815,0.213,30.065l92.385,91.173    H191.362c-11.758,0-21.262,9.504-21.262,21.262c0,11.758,9.504,21.263,21.262,21.263h247.559l-92.385,91.173    c-8.377,8.25-8.441,21.709-0.213,30.065c4.167,4.21,9.653,6.336,15.139,6.336c5.401,0,10.801-2.041,14.926-6.124l129.276-127.575    c4.04-3.997,6.336-9.441,6.336-15.139C512,250.302,509.725,244.88,505.664,240.861z"/>
            </g>
            </svg>
            <span  class="visa-drop-num f500 c555 mar-top-3">Logout</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</header>


<div class="modal searchModal fade" bsModal #searchModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name" [config]="{  backdrop : 'static', keyboard : false }">
  <div class="modal-overlay" (click)="searchModal.hide(); searchInput=''"></div>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="dropdown " dropdown>
        <section class="relative secrch-inp-p" dropdownToggle>
          <input placeholder="I am looking for..." class="search-inp w100 fnt-14" [(ngModel)]="searchInput"
            (input)="debouncedPopulateMerchantList($event)">
          <svg class="search-ic absolute" width="17" height="17" viewBox="0 0 17 17" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.15374 14.3042C8.90454 14.3037 10.5937 13.657 11.8981 12.4878L16.2823 16.8772C16.3612 16.9559 16.4682 17.0001 16.5796 17C16.6911 16.9999 16.798 16.9555 16.8768 16.8766C16.9556 16.7977 16.9999 16.6907 17 16.5791C17.0001 16.4675 16.956 16.3605 16.8773 16.2814L12.4932 11.892C13.5764 10.6666 14.2071 9.10632 14.2801 7.47154C14.3531 5.83676 13.8639 4.22635 12.8943 2.90902C11.9246 1.5917 10.5331 0.647128 8.95182 0.232917C7.37058 -0.181295 5.69528 -0.0400932 4.20546 0.632961C2.71565 1.30601 1.50141 2.47021 0.765362 3.93131C0.0293114 5.3924 -0.184037 7.06201 0.160914 8.66157C0.505866 10.2611 1.38825 11.6939 2.66085 12.7208C3.93344 13.7476 5.51927 14.3066 7.15374 14.3042ZM7.15374 0.82239C8.40215 0.82239 9.62253 1.19303 10.6606 1.88744C11.6986 2.58185 12.5076 3.56883 12.9854 4.72359C13.4631 5.87835 13.5881 7.14902 13.3445 8.3749C13.101 9.60079 12.4998 10.7268 11.6171 11.6106C10.7343 12.4945 9.60959 13.0963 8.38517 13.3402C7.16074 13.584 5.89159 13.4589 4.7382 12.9806C3.58482 12.5023 2.59901 11.6923 1.90542 10.653C1.21184 9.61374 0.841644 8.39191 0.841644 7.14201C0.843527 5.46652 1.50915 3.86019 2.69249 2.67544C3.87583 1.4907 5.48024 0.824275 7.15374 0.82239Z"
              fill="#0F3464" />
          </svg>
          <!-- <span class="ln absolute"></span> -->
          <svg class="close-ic absolute pointer" (click)="searchModal.hide(); searchInput=''" width="15" height="15" viewBox="0 0 15 15"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 1.51071L13.4893 0L7.5 5.98929L1.51071 0L0 1.51071L5.98929 7.5L0 13.4893L1.51071 15L7.5 9.01071L13.4893 15L15 13.4893L9.01071 7.5L15 1.51071Z"
              fill="#333333" />
          </svg>
        </section>
        <div class="dropdown-menu w100 pad-7 se-drop s-dropdown" *dropdownMenu>
          <div class="block loc-results" *ngIf="searchInput.length > 2">

            <!-- <ng-container *ngIf="searchType == 'All' || searchType == 'Offers'">
              <div class="option-box" *ngFor="let merchant of merchantList">
                <a (click)="searchModal.hide()"
                  [routerLink]="'/offers/offer-details/' + merchant.id + '/all/'  + merchant?.attributes?.name?.split(' ').join('-').split(',').join('').split('(').join('').split(')').join('').toLowerCase()"
                  class="option-name c555 f500 flex w100">{{merchant.attributes.name}} - Offer</a>
              </div>
            </ng-container> -->

            <ng-container *ngIf="searchType == 'All' || searchType == 'GiftCards'">
              <div class="option-box" *ngFor="let giftcard of giftcardList">
                <a (click)="searchModal.hide()" [routerLink]="'/giftcards/details/' + giftcard.id" [queryParams]="searchInput"
                  class="option-name c555 f500 flex w100">{{giftcard.attributes.title}} - Gift Card</a>
              </div>
            </ng-container>
            <ng-container *ngIf="searchType == 'All' || searchType == 'Category'">
              <div class="option-box" *ngFor="let category of categoryList">
                <a (click)="searchModal.hide()" [routerLink]="category.url" [queryParams]="searchInput"
                  class="option-name c555 f500 flex w100">{{category.name}} - Category</a>
              </div>
            </ng-container>

            <!-- <ng-container *ngIf="searchType == 'All' || searchType == 'Memberships'">
              <div class="option-box" *ngFor="let membership of membershipList">
                <a (click)="searchModal.hide()" [routerLink]="'/memberships/details/' + membership.id"
                  class="option-name c555 f500 flex w100">{{membership.attributes.name}} - Membership</a>
              </div>
            </ng-container> -->

            <ng-container *ngIf="!searching">
              <ng-container [ngSwitch]="searchType">
                <ng-container *ngSwitchCase="'All'">
                  <div class="option-box" *ngIf="(giftcardList.length ==0) && (categoryList.length == 0)">
                    <div class="no-mer-found">No Such Item Found</div>
                  </div>
                </ng-container>
                <!-- <ng-container *ngSwitchCase="'Offers'">
                  <div class="option-box" *ngIf="(merchantList.length == 0)">
                    <div class="no-mer-found">No Such Merchant Found</div>
                  </div>
                </ng-container> -->
                <ng-container *ngSwitchCase="'GiftCards'">
                  <div class="option-box" *ngIf="(giftcardList.length ==0)">
                    <div class="no-mer-found">No Such GiftCard Found</div>
                  </div>
                </ng-container>
                <!-- <ng-container *ngSwitchCase="'Memberships'">
                  <div class="option-box" *ngIf="(membershipList.length ==0)">
                    <div class="no-mer-found">No Such Membership Found</div>
                  </div>
                </ng-container> -->
              </ng-container>
            </ng-container>
            <ng-container *ngIf="searching">
              <div class="option-box">
                <div class="no-mer-found">Loading...</div>
              </div>
            </ng-container>
          </div>
          <div *ngIf="searchInput.length < 3">
            <div class="option-box">
              <div class="no-mer-found">Type at least 3 characters</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="searchdrop" dropdown>
        <div class="w100 h100 text-truncate pointer inp-txt" dropdownToggle>
          {{searchType}}
        </div>
        <div class="dropdown-menu w100 pad-7 se-drop" *dropdownMenu>
          <div class="block loc-results">
            <div class="option-box" (click)="changeSearchType('All')">
              All
            </div>
            <div class="option-box" (click)="changeSearchType('Offers')">
              Offers
            </div>
            <div class="option-box" (click)="changeSearchType('GiftCards')">
              GiftCards
            </div>
            <div class="option-box" (click)="changeSearchType('Memberships')">
              Memberships
            </div>
          </div>
        </div>
        <span class="icon-down-open-mini"></span>
      </div> -->
    </div>
  </div>
</div>

<pv-token-expired [moduleConfig]="tokenExpiredModuleConfig" [show]="showTokenExpiredModal" (openAuthModal)="login()"></pv-token-expired>