<div class="sp-section dark4 center">
    <div class="row background404 w100">
        <div class="container text-white d-flex justify-content-start align-center row">
            <div class="col-md-8 col-sm-12 mb-40">
                <h2 class="sp-heading accenttwo mb-40">The page requested by you does not exist at this location.</h2>
                <h6 class="sp-heading gold">Auto-redirecting to homepage in {{autoRedirectTime}} seconds...</h6>
            </div>
            <div class="col-md-4 col-sm-12">
                <img src="https://images.poshvine.com/visa-srilanka/icons/404.png" class="w100">
            </div>
        </div>
    </div>
</div>