import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: "root"
})
export class ResponsiveCheck {
  constructor(
    @Inject(DOCUMENT) private document: any
  ) {}



  onResize() {
    let deviceObj = {
      isDesktopDevice: false,
      isTablet: false,
      isMobile: false
    };
    if (window.innerWidth > 1024) {
      deviceObj.isDesktopDevice = true;
    }
    else if (window.innerWidth > 992) {
      deviceObj.isTablet = true;
    }
    else {
      deviceObj.isMobile = true;
    }
    return deviceObj;
  }

  iosWebViewCheck(){
    let standalone = ('standalone' in window.navigator) && (window.navigator['standalone']);
    let userAgent = window.navigator.userAgent.toLowerCase();
    let safari = /safari/.test( userAgent );
    let ios = /iphone|ipod|ipad/.test( userAgent );

    if( ios && !standalone && !safari ) {
        return true;
    } else {
        return false;
    };
  }
}
