import { PointsXChangeConfig } from "@pv-frontend/points-xchange";

export const pointsXChangeConfig: PointsXChangeConfig = {
  global: {
    config: {
      pointsLabel: 'White Pass',
      defaultBurnRatio: 1,
    },
  },
  landingPage: {
    listing: {
      style: {
        categoryContainer: {
          'display': 'none'
        }
      },
      config: {
        headingText: 'Our trusted point transfer partners',
        categories: {
          showCrossIcon: true,
          isMultiSelect: true,
          variant: 'outlined',
        },
        pointsProvider: {
          isPaginationRequired: true,
        },
      },
    },
    banners: {
      style: {
        userSalutationText: {
          'display': 'none'
        },
        welcomeText: {
          'display': 'none'
        },
        transferPointsText: {
          'font-size': '20px'
        },
        container: {
          'height': '18rem'
        }
      },
      config: {
        transferPointsText: 'Maximize your White Pass with Miles Transfer',
        journeyStepsData: {
          stepsAutoPlayInterval: 5000,
          bannerSteps: [
            {
              title: 'Pick Your Partner',
              subTitle:
                'Select from our curated list of trusted partners to transfer your points.',
              bannerNumber: '1',
            },
            {
              title: 'Seamlessly Link Your Account',
              subTitle:
                'Connect your partner account effortlessly to kickstart the magic.',
              bannerNumber: '2',
            },
            {
              title: 'Transfer Your Points',
              subTitle: 'Effortlessly move your points to your chosen partner account.',
              bannerNumber: '3',
            },
          ]
        }
      }
    }
  },
  transferHistoryPage: {
    style: {
      container: {
        'background-color': '#fff !important'
      },
      subContainer: {
        width: '100%'
      },
      navBar: {
        container: {
          display: 'none'
        }
      },
      listing: {
        transferData: {
          container: {
            border: '1px solid #e4e5e7',
            borderRadius: '12px'
          }
        }
      }
    }
  },
  transferHistoryDetailsPage: {
    config: {
      backNavUrl: {
        route: ['booking', 'home'],
        queryParams: {app: 'Points Xchange'}
      }
    }
  }
};