import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from 'src/app/config/global-variables';


const loginApis = [
  'cs/v1/bins/validate'
]

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    @Inject(DOCUMENT) private document: any,
    public GlobalConstants: GlobalConstants,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = request.url;
    console.log(url.split('//'))
    url = url.split('//').length >=3 ? url.split('').reverse().join('').replace('//','/').split('').reverse().join('') : url;
    request = request.clone({url:url});
    if (request.url.includes("restcountries") || request.url.includes("ipify.org") || request.url.includes("postalpincode") || request.url.includes("opencagedata")) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '-1',
        'X-Download-Options': 'noopen',
        'X-Client-Id': environment.clientId
      }
    });
    if(!request.headers.get('exclude_project_id')){
      let userCard = localStorage.getItem("userCard") ? JSON.parse(localStorage.getItem("userCard")) : {};
      let projectId = environment.projectId;
      if(userCard.attributes && userCard.attributes.segment && userCard.attributes.segment == "white-reserve") {
        projectId = environment.whiteReserveProjectId;
      }
      request = request.clone({
        setHeaders: {
          'X-Project-Id': projectId
        }
      });
    }
    if (localStorage.getItem('userCountry')) {
      let userCountry = JSON.parse(localStorage.getItem('userCountry'));
      if(userCountry.id){
        request = request.clone({
          setHeaders: {
            'X-Country-Id': userCountry.id
          }
        });
      }

    }

    var currentUser;
    if (localStorage.getItem('currentUser')) {
      currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser && currentUser.token) {
        request = request.clone({
          setHeaders: {
            'Authorization': 'Bearer ' + currentUser.token
          }
        });
      }
    }

    return next.handle(request)
      .pipe(
        tap(
          (response: HttpEvent<any>) => {
            return response
          },
          (error: HttpErrorResponse) => {
            return error
          },
          () => {
          }
        )
      )
  }
}
