import { TransactionsModule } from './pages/transactions/transactions.module';
import { PhilanthrophyModule } from './pages/philanthrophy/philanthrophy.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error500Component } from './pages/error500/error500.component';
import { Error404Component } from './pages/error404/error404.component';
import { AuthGuard } from 'src/app/authentication/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import("./pages/homepage/homepage.module").then(m => m.HomepageModule),
    canActivate: [AuthGuard], // visit home only if authenticated
  },
  {
    path: "giftcards",
    loadChildren: () => import("./pages/gift-cards/gift-cards.module").then(m => m.GiftCardsModule)
  },
  {
    path: "hotels",
    loadChildren: () => import("./pages/hotels/hotels.module").then(m => m.HotelsModule)
  },
  {
    path: 'flights',
    loadChildren: () => import("./pages/flights/flights.module").then(m => m.FlightsModule)
  },
  {
    path: 'charitable-donations',
    loadChildren: () => import("./pages/philanthrophy/philanthrophy.module").then(m => m.PhilanthrophyModule)
  },
  {
    path: 'redemption-history',
    loadChildren: () => import("./pages/redemption/redemption.module").then(m => m.RedemptionModule)
  },
  {
    path: 'cashback',
    loadChildren: () => import("./pages/cashback/cashback.module").then(m => m.CashbackModule)
  },
  { path: "error", component: Error500Component },
  {
    path: 'info',
    loadChildren: () => import("./info/info.module").then(m => m.InfoModule)
  },
  {
    path: "nat-geo",
    loadChildren: () => import("./pages/nat-geo/nat-geo.module").then(m => m.NatGeoModule)
  },
  {
    path: "golf",
    loadChildren: () => import("./pages/golf/golf.module").then(m => m.GolfModule)
  },
  {
    path: "golf-enquiry",
    loadChildren: () => import("./pages/golf/golf.module").then(m => m.GolfModule)
  },
  {
    path: "booking",
    loadChildren: () =>
      import("./pages/booking-history/booking-history.module").then(
        m => m.BookingHistoryModule
      )
  },
  {
    path: "transactions",
    loadChildren: () => import("./pages/transactions/transactions.module").then(m => m.TransactionsModule)
  },
  {
    path: "booking-failed",
    loadChildren: () => import("./pages/booking-failed/booking-failed.module").then(m => m.BookingFailedModule)
  },
  {
    path: 'sso_login',
    loadChildren: () => import("./pages/loginSso/loginSso.module").then(m => m.LoginSsoModule)
  },
  // {
  //   path: 'points-xchange',
  //   loadChildren: () =>
  //     import('@pv-frontend/points-xchange').then((m) => m.PvPointsXchangeModule),
  // },
  { path: "**", pathMatch: "full", component: Error404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
