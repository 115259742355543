import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataTransferService {

  private message = new BehaviorSubject<any>({});
  private countries = new BehaviorSubject<any>({});
  private bannerInterval = new BehaviorSubject<number>(null);
  private reloadEvent = new BehaviorSubject<number>(null);
  private componentReload = new BehaviorSubject<boolean>(null);
  sharedMessage = this.message.asObservable();
  sharedCountries = this.countries.asObservable();
  sharedBannerInterval = this.bannerInterval.asObservable();
  sharedReloadEvent = this.reloadEvent.asObservable();


  constructor() { }

  nextMessage(message) {
    this.message.next(message)
  }
  nextCountries(countries) {
    this.countries.next(countries)
  }
  nextBannerInterval(bInterval) {
    this.bannerInterval.next(bInterval)
  }
  nextReloadEvent(bInterval) {
    this.reloadEvent.next(bInterval)
  }

}
