import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
// import { GlobalConstants } from 'src/app/config/global-variables';

@Injectable()
export class SpecialProjectCheck {
    subProjectLinks = [
      'paytm-wellnessoffers'
    ];
    constructor(
      @Inject(DOCUMENT) private document: any
    ) {

    }
    checkProject(){
      let pathname = this.document.location.pathname.split('/',3);
      if(this.subProjectLinks && this.subProjectLinks.includes(pathname[1])){
        return {
          type: 'special',
          code: pathname[1]
        }
      }
      else{
        return {
          type: 'default'
        }
      }
    }
}
