import { Component, HostListener, Input, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/config/global-variables';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() isExtraPaddingRoute = false;

  constructor(public GlobalConstants: GlobalConstants,) { }

  ngOnInit() {
    this.onResize();
  }

  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktopDevice: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth > 1024) {
      this.isDesktopDevice = true;
      this.isTablet = false;
      this.isMobile = false;
      // this.ngOnInit();
    }
    else if (window.innerWidth > 992) {
      this.isDesktopDevice = false;
      this.isTablet = true;
      this.isMobile = false;
      // this.ngOnInit();
    }
    else {
      this.isDesktopDevice = false;
      this.isTablet = false;
      this.isMobile = true;
      // this.ngOnInit();
    }
    // this.innerWidth = window.innerWidth;
  }

}
