import { GiftcardService } from "src/app/pages/gift-cards/service/giftcard.service";
import { Component, ViewChild, Inject } from "@angular/core";
import { NavigationEnd, Router, ActivatedRoute } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { environment } from "src/environments/environment";
import { GlobalConstants } from "./config/global-variables";
import { DOCUMENT } from "@angular/common";
import { filter } from "rxjs/operators";
import { ResponsiveCheck } from "src/app/shared/services/common/responsive-check.service";

declare function require(name: string): any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  extraPaddingRoutes = [
    "/charitable-donations/donation",
    "/giftcards/checkout",
    "/hotels/traveller-detail",
    "/nat-geo/listing-details",
  ];
  isExtraPaddingRoute = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private update: SwUpdate,
    public GlobalConstants: GlobalConstants,
    private giftCardService: GiftcardService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    public _responsiveCheck: ResponsiveCheck
  ) {
    localStorage.setItem("firstLoad", "true");
    this.addScript(
      `https://maps.googleapis.com/maps/api/js?key=${environment.gmApiKey}`
    );
    this.updateClient();
  }

  viewType;
  ngOnInit() {
    if (this.GlobalConstants && this.GlobalConstants.data) {
      if (this.GlobalConstants.data.font_family) {
        this.addStyleSheet(this.GlobalConstants.data.font_family.link);
      }
      if (
        this.GlobalConstants.data.logo &&
        this.GlobalConstants.data.logo.favicon
      ) {
        this._document
          .getElementById("appFavicon")
          .setAttribute("href", this.GlobalConstants.data.logo.favicon);
      }
      if (
        this.GlobalConstants.data.colors &&
        this.GlobalConstants.data.colors.header_bg_color
      ) {
        this._document
          .getElementById("appColor")
          .setAttribute(
            "content",
            this.GlobalConstants.data.colors.header_bg_color
          );
      }
    }
    this.setExtraPaddingOnNavigation();
  }
  updateClient() {
    if (!this.update.isEnabled) {
      console.log("Not Enabled");
      return;
    }
    this.update.available.subscribe((event) => {
      this.update.activateUpdate().then(() => location.reload());
    });

    this.update.activated.subscribe((event) => {
      console.log(`current`, event.previous, `available `, event.current);
    });
  }
  addStyleSheet(fontLink) {
    var headID = document.getElementsByTagName("head")[0];
    var link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.id = "widget_styles";
    headID.appendChild(link);
    link.href = fontLink;
  }
  addScript(linkurl) {
    var headID = document.getElementsByTagName("head")[0];
    var link = document.createElement("script");
    link.src = linkurl;
    link.async = true;
    headID.appendChild(link);
  }

  private componentBeforeNavigation = null;
  private setExtraPaddingOnNavigation() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let currentRoute = this.route;
        while (currentRoute.firstChild) currentRoute = currentRoute.firstChild;
        if (this.componentBeforeNavigation !== currentRoute.component) {
          const isGiftCardRoute = this.router.url.includes("giftcards");
          this.giftCardService.updateGCRoute(isGiftCardRoute);
          console.log("changed", this.router.url);
          if (
            this.extraPaddingRoutes.some((route) =>
              this.router.url.includes(route)
            )
          )
            this.isExtraPaddingRoute = true;
          else this.isExtraPaddingRoute = false;
        }
        this.componentBeforeNavigation = currentRoute.component;
      });
  }
}
