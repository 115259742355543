import { ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { GiftcardService } from "src/app/pages/gift-cards/service/giftcard.service";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { ResponsiveCheck } from '../../services/common/responsive-check.service'

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class CartComponent implements OnInit, OnDestroy {
  @Input() data: string[];
  @Input() isListingPage: boolean;
  // @Input() allGiftcards = [];
  @Output() apiStatus = new EventEmitter<boolean>();
  @Output() cartResponse = new EventEmitter<object>();
  @Output() initialCartResponse = new EventEmitter<object>();

  cartItemsArr: any;
  valueNum: any = [];
  progress: boolean = true;
  cartProgress: boolean = false;
  isUserAuthenticated: boolean = false;
  @ViewChild('modalone') modalone;
  openModal = false;
  itemCount = 0;


  avlPoints;

  constructor(
    public giftcardService: GiftcardService,
    public router: Router,
    private toastr: ToastrService,
    private _auth: AuthenticationService,
    public _responsiveCheck: ResponsiveCheck
    // private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {

    // this.checkIfAuthenticated();
    // if(this.isUserAuthenticated){



      
      
    // }
    this._auth.currentUser.subscribe((res) => {
      if(res) {
        this.isUserAuthenticated = true;
        this.giftcardService.cartItems$.subscribe(res => {
          // this.checkIfAuthenticated();
          if(this.cartItemsArr?.length !== res?.length){
            this.cartItemsArr = res; 
            this.getCountOfItems();
  
          }
        });
  
        this.getGiftcardsInCart();
        this.avlPoints = JSON.parse(sessionStorage.getItem('points'));
        // this.cdRef.detectChanges();
      }
      else{
        this.isUserAuthenticated = false;
        // this.cdRef.detectChanges();
      }
    })
  }
  checkIfAuthenticated() {
    if (localStorage.getItem("currentUser") && localStorage.getItem("card_verified")) {
      this.isUserAuthenticated = true;
    }
    else{
      this.isUserAuthenticated = false;
    }
  }
  ngOnChanges(change) {
    if (this.data) {
      this.pushToCart(this.data);
    }
  }

  ngOnDestroy() {
    this.closeCart();
  }

  getGiftcardsInCart() {
    this.giftcardService.getGiftcardsInCart().then((res: any) => {
      this.cartItemsArr = res.data;
      this.getCountOfItems();
      // this.cartResponse.emit(this.cartItemsArr);
      this.initialCartResponse.emit(this.cartItemsArr);
      this.progress = false;
      this.removeFromCart();
    });
  }

  otherThanNull(arr) {
    return arr.filter(function (el) {
      if (el !== false || el !== null || el !== 0 || el !== "") {
        return el;
      }
    }).length ? false : true;
  }

  async pushToCart(data) {
    if(this.cartItemsArr && Array.isArray(this.cartItemsArr) && this.cartItemsArr.length > 0) {
      for (const el of this.cartItemsArr) {
        if (el.attributes.details.gift_card_id == data.id && el.attributes.details.recipient_type == data.recipient_type) {
          if (!data.denominations.includes(el.attributes.details.denomination)) {
            await this.deleteFromCart(el.id);
          }
        }
      }
    }

    const formData = {
      'category': 'GiftCard',
      'items': []
    }
    data.valueNum.forEach((obj, index) => {
      formData.items.push({
        'gift_card_id': data.id,
        'name': data.name,
        'denomination': data.denominations[index],
        'quantity': obj,
        'recipient_type': data.recipient_type
      })
    });
    this.giftcardService.pushToCart(formData).subscribe((res: any) => {
      if (res && res.data) {
        this.cartItemsArr = res.data;
      }
      this.getCountOfItems();
      this.apiStatus.emit(true);
      this.cartResponse.emit(this.cartItemsArr);
      this.removeFromCart();
      this.openModal = true;


      // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, error => {
      this.apiStatus.emit(false);
    });
  }

  goToCheckout() {
    this.openModal = false;
    // this.giftcardService.checkout().subscribe(res => {
    this.router.navigate(['giftcards', 'checkout']);
    // })
  }

  getCountOfItems() {
    let count = 0
    this.giftcardService.updateCartData(this.cartItemsArr);
    if (this.cartItemsArr && this.cartItemsArr.length) {
      this.cartItemsArr.forEach(element => {
        if (element.attributes.details && element.attributes.quantity) {
          count = count + element.attributes.quantity;
        }
      });
    }
    this.itemCount = count;
    return count;
  }

  getImageUrl(cartItem) {
    if (this.giftcardService.allGiftcards && this.giftcardService.allGiftcards.length) {
      for (let each of this.giftcardService.allGiftcards) {
        if (each.id == cartItem.attributes.details.gift_card_id) {
          return each.attributes.image_url;
        }
      }
    }
    return '';
  }

  async removeFromCart() {
    if (this.cartItemsArr && this.cartItemsArr.length) {
      for (let each of this.cartItemsArr) {
        if (each.attributes.quantity == 0) {
          await this.deleteFromCart(each.id);
        }
      }
      this.getCountOfItems();
    }
  }

  deleteFromCart(id) {
    return new Promise((resolve) => {
      const deleteData = {
        item_id: id,
      }
      this.giftcardService.deleteFromCart(deleteData, undefined).then((res: any) => {
        if (res && res.data) {
          this.cartItemsArr = res.data;
          this.getCountOfItems();
        }
        this.apiStatus.emit(true);
        this.cartResponse.emit(this.cartItemsArr);
        resolve(true);
      });
    });
  }


  clearCart() {
    this.cartProgress = true;
    this.giftcardService.clearCartItems().then((res: any) => {
      if (res && res.status == 'success') {
        this.cartItemsArr = [];
        this.getCountOfItems();
      }
      this.apiStatus.emit(true);
      this.cartResponse.emit(this.cartItemsArr);
      this.cartProgress = false;
    });
  }

  increment(gift_card_id, denomination, quantity, recipient_type, name) {
    quantity = parseInt(quantity) + 1;
    if (quantity <= this.getMaxQuantityPerOrder(gift_card_id)) {
      let data = [];
      data.push({
        item_id: gift_card_id,
        item_name: name,
        denomination: denomination,
        quantity: quantity,
        recipient_type: recipient_type
      });
      this.addToCart(data);
    } else {
      this.toastr.error('No of quantity cannot be more than ' + this.getMaxQuantityPerOrder(gift_card_id) + '.');
    }
  }

  decrement(item_id, gift_card_id, denomination, quantity, recipient_type, name) {
    quantity = parseInt(quantity) - 1;
    if (quantity > 0) {
      let data = [];
      data.push({
        item_id: gift_card_id,
        item_name: name,
        denomination: denomination,
        quantity: quantity,
        recipient_type: recipient_type
      });
      this.addToCart(data);
    } else {
      this.deleteFromCart(item_id);
    }
  }

  addToCart(data) {
    const formData = {
      'category': 'GiftCard',
      'items': []
    }
    data.forEach(eachdata => {
      formData.items.push({
        'gift_card_id': eachdata.item_id,
        'name': eachdata.item_name,
        'denomination': eachdata.denomination,
        'quantity': eachdata.quantity,
        'recipient_type': eachdata.recipient_type
      })
    });

    this.giftcardService.pushToCart(formData).subscribe((res: any) => {
      if (res && res.data) {
        this.cartItemsArr = res.data;
        this.getCountOfItems();
      }
      this.apiStatus.emit(true);
      this.cartResponse.emit(this.cartItemsArr);
      this.removeFromCart();
    });
  }

  getMaxQuantityPerOrder(id) {
    if (this.giftcardService.allGiftcards && this.giftcardService.allGiftcards.length) {
      for (let each of this.giftcardService.allGiftcards) {
        if (each.id == id) {
          return each.attributes.max_quantity_per_order;
        }
      }
    }
    return 4;
  }

  toggle() {
    if(!this.giftcardService.allGiftcards || !this.giftcardService.allGiftcards.length){
      this.giftcardService.getAllGiftCards();
    }
    this.openModal = true;
  }

  closeCart() {
    this.openModal = false;
    // this.modalone.hide();
  }

  getTotalCartAmount() {
    let total = 0;
    this.cartItemsArr.forEach((each) => {
      if (each && each.attributes.quantity) {
        total = total + (each.attributes.details.denomination * each.attributes.quantity);
      }
    });
    return total;
  }

  navigateToListing() {
    this.router.navigate(['/giftcards']);
  }





  getPointsAvailable() {
    return this.avlPoints;
  }



}
