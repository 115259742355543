import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalConstants } from 'src/app/config/global-variables';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor( private router: Router, public GlobalConstants: GlobalConstants) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (!this.GlobalConstants.data.homepage_needed) {
          this.router.navigate(['offer/offer-details/8dcd7fd5-d560-45c9-96e2-69d4568697f9/all/dropbox']);
          return false;
      }
    return true;
  }
}
