import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ImagePreloadDirective } from './directives/image-preload.directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NoDataComponent } from './components/no-data/no-data.component';
import { FooterComponent } from './components/footer/footer.component';
import { CouponModalComponent } from './components/coupon-modal/coupon-modal.component';
import { GiftCardComponent } from './components/gift-card/gift-card.component';
import { PromoTncComponent } from "src/app/shared/components/promo-tnc/promo-tnc.component";
import { StatusLabelComponent } from 'src/app/shared/components/status-label/status-label.component';
import { TabsCartComponent } from './components/tabs-cart/tabs-cart.component';
import { TermsModalComponent } from './components/terms-modal/terms-modal.component';
import { CouponCodeComponent } from './components/coupon-code/coupon-code.component';
import { CartComponent } from './components/cart/cart.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxDaterangepickerMd } from './components/daterangepicker';

import { OffersCardComponent } from './components/offers-card/offers-card.component';
import { RouterModule } from '@angular/router';

import { LazyImgDirective } from './directives/lazyload.directive';
import { AuthenticationModule } from '../authentication/authentication.module';
import { HeaderComponent } from './components/header/header.component';
import { CardSwitchComponent } from './components/card-switch/card-switch.component';
import { CountryModalComponent } from './components/country-modal/country-modal.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CardLoaderComponent } from './components/loader/card-loader/card-loader.component';
import { SliderComponent } from './components/slider/slider.component';
import { NotEligibleModalComponent } from './components/not-eligible-modal/not-eligible-modal.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { NumbersOnlyDirective } from './directives/numbers-only.directive';

import {MatLegacyDialogModule as MatDialogModule, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';

import { BusTimingPipe } from './pipes/bus-timing.pipe';
import { CleanHtmlPipe } from './pipes/clean-html.pipe';
import { CreditCardMaskPipe } from './pipes/credit-card-mask.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { IncrementDecrementPipe } from './pipes/increment-decrement.pipe';
import { IndianCurrency } from './pipes/indian-currency.pipe';
import { MinuteSecondsPipe } from './pipes/minute-seconds.pipe';
import { RemoveUnderscorePipe } from 'src/app/shared/pipes/remove-underscore.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { Time24to12Pipe } from './pipes/time24to12.pipe';

import { NgxPaginationModule } from "ngx-pagination";
import { RouterExtService } from 'src/app/shared/services/router-ext.service';
import { NgbDatepickerModule, NgbModule, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { PvTokenExpiredModule } from "@pv-frontend/authentication";

@NgModule({
    declarations: [
        // Components
        NoDataComponent,
        FooterComponent,
        CouponModalComponent,
        OffersCardComponent,
        HeaderComponent,
        CardSwitchComponent,
        CountryModalComponent,
        LoaderComponent,
        CardLoaderComponent,
        NotEligibleModalComponent,
        SliderComponent,
        GiftCardComponent,
        PromoTncComponent,
        StatusLabelComponent,
        TabsCartComponent,
        TermsModalComponent,
        CouponCodeComponent,
        CartComponent,
        // Directives
        ImagePreloadDirective,
        LazyImgDirective,
        NumbersOnlyDirective,
        // Pipes
        BusTimingPipe,
        CleanHtmlPipe,
        CreditCardMaskPipe,
        FilterPipe,
        IncrementDecrementPipe,
        IndianCurrency,
        MinuteSecondsPipe,
        RemoveUnderscorePipe,
        ReplacePipe,
        Time24to12Pipe,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
            closeButton: true,
            preventDuplicates: true,
            // disableTimeOut: true
        }),
        NgxDaterangepickerMd.forRoot({
            firstDay: 0,
        }),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        ModalModule.forRoot(),
        RouterModule,
        AuthenticationModule,
        NgxPaginationModule,
        MatDialogModule,
        MatRadioModule,
        NgxSliderModule,
        NgbDatepickerModule,
        NgbModule,
        PvTokenExpiredModule
    ],
    exports: [
        // Modules
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule,
        ModalModule,
        RouterModule,
        NgxDaterangepickerMd,
        NgxPaginationModule,
        MatDialogModule,
        MatRadioModule,
        PopoverModule,
        NgxSliderModule,
        NgbDatepickerModule,
        NgbModule,
        // Components
        NoDataComponent,
        FooterComponent,
        CouponModalComponent,
        OffersCardComponent,
        HeaderComponent,
        CardSwitchComponent,
        CountryModalComponent,
        LoaderComponent,
        CardLoaderComponent,
        NotEligibleModalComponent,
        SliderComponent,
        GiftCardComponent,
        PromoTncComponent,
        StatusLabelComponent,
        TabsCartComponent,
        TermsModalComponent,
        CouponCodeComponent,
        CartComponent,
        // Directives
        ImagePreloadDirective,
        LazyImgDirective,
        NumbersOnlyDirective,
        // Pipes
        BusTimingPipe,
        CleanHtmlPipe,
        CreditCardMaskPipe,
        FilterPipe,
        IncrementDecrementPipe,
        IndianCurrency,
        MinuteSecondsPipe,
        RemoveUnderscorePipe,
        ReplacePipe,
        Time24to12Pipe,
    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        RemoveUnderscorePipe,
        RouterExtService
    ]
})
export class SharedModule { }
