<section [ngStyle]="{'display': isListingPage ? 'none' : 'block'}" *ngIf = "isUserAuthenticated">
  <div *ngIf="!progress">
      <div class="" (click)="toggle()">
        <div class="relative pointer d-flex align-center">
          
          <span class="cart-num fnt-12 f500 flex absolute align-center justify-center"
            *ngIf="itemCount">{{itemCount}}</span>
        </div>
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 22C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20C8.44772 20 8 20.4477 8 21C8 21.5523 8.44772 22 9 22Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20 22C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20C19.4477 20 19 20.4477 19 21C19 21.5523 19.4477 22 20 22Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 1H5L7.68 14.39C7.77144 14.8504 8.02191 15.264 8.38755 15.5583C8.75318 15.8526 9.2107 16.009 9.68 16H19.4C19.8693 16.009 20.3268 15.8526 20.6925 15.5583C21.0581 15.264 21.3086 14.8504 21.4 14.39L23 6H6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
  </div>
  <div *ngIf="progress" class="d-flex justify-center align-center progress-dots">
    <div class="dot-flashing"></div>
  </div>
</section>
<div class="right" [class] = "openModal ? 'open-m' : 'close-m'">
  <div class="modal-dialog">
    <div class="modal-content cart-container" [class.ios-web-view] = "_responsiveCheck.iosWebViewCheck()">
      <div class="cart-header">
        <div class="my-cart">
          My Cart
        </div>
        <div class="icon-cancel absolute pointer" (click)="closeCart()">
          <img src = "https://images.poshvine.com/kotak/icons/close.png" alt = "close">
        </div>
      </div>
      <ng-container *ngIf="cartItemsArr?.length">
        <div class="cart-items-container">
          <div class="cart-item flex mar-bot-15" *ngFor="let cartItem of cartItemsArr">
            <div class="item-img">
              <img class="img-fluid" default="https://images.poshvine.com/b/default-thumbnail-square.jpg"
                [src]="getImageUrl(cartItem) ? getImageUrl(cartItem) : 'assets/default-giftcard.png'" alt="gift image">
            </div>
            <div class="item-info flex">
              <div class="info-c">
                <div class="card-name" *ngIf="cartItem?.attributes?.details?.name">
                  {{cartItem.attributes.details.name}}</div>
                <div class="flex">
                  <div class="pad-right-6">
                    <svg _ngcontent-cvs-c14="" fill="none" height="16" viewBox="0 0 16 16" width="16"
                      xmlns="http://www.w3.org/2000/svg">
                      <circle _ngcontent-cvs-c14="" cx="8" cy="8" fill="#F8F9F9" r="8"></circle>
                      <path _ngcontent-cvs-c14="" clip-rule="evenodd"
                        d="M7.10861 10.1056L5.32146 8.31843L4.71289 8.92272L7.10861 11.3184L12.2515 6.17557L11.6472 5.57129L7.10861 10.1056Z"
                        fill="#323937" fill-rule="evenodd"></path>
                      <mask _ngcontent-cvs-c14="" height="7" id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse"
                        width="9" x="4" y="5">
                        <path _ngcontent-cvs-c14="" clip-rule="evenodd"
                          d="M7.10861 10.1056L5.32146 8.31843L4.71289 8.92272L7.10861 11.3184L12.2515 6.17557L11.6472 5.57129L7.10861 10.1056Z"
                          fill="white" fill-rule="evenodd"></path>
                      </mask>
                    </svg>
                  </div>
                  <div class="info-txt-c fnt-12">
                    <div class="info-val-txt">Denomination</div>
                    <div class="mar-top-3 info-num f500"><span class="rupee-symbol ">₹</span>
                      {{cartItem?.attributes?.details?.denomination | indianCurrency}}</div>
                  </div>
                </div>
              </div>
              <div class="flex info-c justify-content-end">
                <div class="info-txt-c fnt-12">
                  <div class="custom-counter d-flex justify-content-between align-items-center mar-top-3">
                    <button class="counter-decrement-button"
                      (click)="decrement(cartItem.id, cartItem?.attributes?.details?.gift_card_id, cartItem?.attributes?.details?.denomination, cartItem?.attributes?.quantity, cartItem?.attributes?.details?.recipient_type, cartItem?.attributes?.details?.name)">-</button>
                    <div class="bg counter-text d-flex justify-content-center align-items-center quantity">
                      {{cartItem?.attributes?.quantity ? cartItem?.attributes?.quantity : 0}}
                    </div>
                    <button class="counter-increment-button"
                      (click)="increment(cartItem?.attributes?.details?.gift_card_id, cartItem?.attributes?.details?.denomination, cartItem?.attributes?.quantity, cartItem?.attributes?.details?.recipient_type, cartItem?.attributes?.details?.name)">+</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-container" >
          <div class="d-flex justify-content-between align-center total-amount">
            <div class="key">Total Amount</div>
            <div class="value">
              <span class="rupee-symbol mar-right-4">₹</span>
              <span>{{getTotalCartAmount() | indianCurrency}}</span>
            </div>
          </div>

          <div class="d-flex justify-content-between align-center total-amount">
            <div class="key">White Pass available for Redemption</div>
            <div class="value">
              <span>{{getPointsAvailable()}}</span>
            </div>
          </div>

          <div class="proceed-btn d-none d-md-block">
            <button class="primary-btn w100" (click)="goToCheckout()">Proceed to Checkout</button>
          </div>
          <div class="continue d-flex justify-content-between align-center">
            <span class="pointer" (click)="closeCart();navigateToListing()">
              <span class="icon-angle-left" style="vertical-align: -2px;"></span>
              <span class="mar-left-4">Continue shopping</span>
            </span>
            <div class="proceed-btn d-block d-md-none ">
              <button class="primary-btn w100" (click)="goToCheckout()">Checkout</button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!(cartItemsArr?.length)">
        <div class="cart-items-container fnt-16 f500">
          No item(s) in cart!
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-backdrop" *ngIf = "openModal" (click) = "openModal = false"></div>
