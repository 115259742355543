import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: "root"
})
export class GlobalConstants {
    client: any;
    data: any;
    constructor(@Inject(DOCUMENT) private document: any, private router: Router) {
            this.client = "kotak-mahindra-bank";
            this.data = {
                    "logo": {
                        "url": "https://images.poshvine.com/logos/kotak-white-e.png",
                        "footerurl": "./assets/favicon/kotak.ico",
                        "desktop_url": "https://images.poshvine.com/logos/kotak-white-e.png",
                        "modal_url": "https://images.poshvine.com/logos/kotak-white-e.png",
                        "favicon": "./assets/favicon/kotak.ico",
                        "head_logo_height": "60",
                        "coupon_modal_logo": "https://images.poshvine.com/logos/kotak-white-e.png"
                    },
                    "assets": {
                        "img": {},
                        "manifest": "manifest.webmanifest"
                    },
                    "links": {
                        "projectLink": "https://kotak-mahindra-bank.poshvine.com/"
                    },
                    "colors": {
                      "header_bg_color": "#fff",
                      "coupon_modal_bg_color": "#fff",
                      "offer_title_color": '#003366',
                      "main_text_color": '#262626',
                      "medium_text_color": '#5C5C5C',
                      "light_text_color": '#9D9D9D',
                      "main_bg_color": '#555a5b',
                      "active_card_bg_color": '#F4F4F4',
                      "seconday_bg_color": '#f5f5f5',
                      "mainBtn_color": '#bca36d',
                      "inactive_btn_color": '#D8D8D8',
                      "btn_positive_action_color": '#06B08A',
                      "active_text_color": '#bca36d',
                    },
                    "legal_name": "Kotak",
                    "font_family": {
                        "link": "https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap",
                        "name": "Roboto"
                    },
                    "x_header": {
                            "prod": {
                                'client_id': '0dabaa48-646f-411b-a4d5-a3d422c61c63',
                                'main_project': 'd5dacbae-f0e4-4594-adea-78ce733a3b42',
                                'sub_project': 'd5dacbae-f0e4-4594-adea-78ce733a3b42'
                            },
                            "sandbox": {
                                'client_id': '10f358ea-d651-4d2c-b969-f33d79f83c71',
                                'main_project': 'a6a55986-d69d-4b91-ab5f-4168c787c9ab',
                                'sub_project': 'a6a55986-d69d-4b91-ab5f-4168c787c9ab'
                            },
                            "default_card_type": 'credit'
                    },
                    "email_needed": true,
                    "homepage_needed": true,
                    "search_type": 1,
                    "desktop_category_count": 5,
                    "mobile_category_count": 3,
                    "card_num_type": 'last4',
                    "card_type": "Debit/Credit",
                    "tabs_type": "subcategory",
                    "tabs_type_mobile": "subcategory",
                    "has_exclusive": false,
                    "banner_type": "static",
                    "login_button_needed": true,
                    "loginAt": "otp",
                    "login_theme": 1,
                    "login_steps": ['mobile','otp','card',],
                    "auto_expiry": {
                        "time" : "",
                        "enabled" : false
                    },
                    "template" : 1,
                    "header_type": "default",
                    "show_saving_for_bundle_codes": {},
                    "download_app_link": {
                        'header': false,
                        'footer': false,
                        'android_link': '',
                        'apple_link': ''
                    },
                    "login_modal_type": "desktop-prev-header",
                    "supported_devices": ['web','mobile'],
                    "benefits":['offers'],
                    "banner_style": 1,
                    "carouselInterval": -1,
                    "api_version": 'v2',
                    "personalized_config": {
                        "coupon_terms_link": false,
                        "switch_card": false,
                        "autocard_check": false,
                        "view_savings": true,
                        "external_otp": false,
                        "redemption_page_needed": true,
                        "saving_page": false,
                        "resend_allowed": true,
                        "add_new_card": true,
                        "footer_logo": false,
                        "force_auth": false,
                        "token_auth": false,
                        "desktop_search": true,
                        "mobile_search": true,
                        "auth": true,
                        "coupon_modal": true,
                        "coupon_show": true,
                        "amount_show": false,
                        "modal_redeem_button": true,
                        "mobile_home_back_button": true,
                        "show_card_used": false,
                        "show_bundle_used": false,
                        "allow_user_create": false,
                        "dedicated_banner": false,
                        "mobile_banner": true,
                        "authenticate_before_claim": true,
                        "disclaimer_popup": false,
                        "cancelable_auth": true,
                        "display_bundle_category_title": true,
                        "mobile_login": true,
                        "is_concierge": false,
                        "has_card": true,
                        "home_subcategory_dropdown": false,
                        "home_subcategory_bar": false,
                        "has_near_by": false,
                        "has_international": false,
                        "mobile_profile_dropdown": true,
                        "category_page": true,
                        "authenticate_bin": true,
                        "datalayer": false,
                        "custom_gtm_tags": false,
                        "show_terms_before_redeem": false,
                        "details_desktop_tabs": false,
                        "show_special_category": false,
                        "send_project_id": true,
                        "show_card_applicability": false,
                        "card_applicability_fetch": 'api',
                        "show_card_select_on_auth": false,
                        "special_bundle": false,
                        "external_login_banner": false,
                        "has_international_offers": false,
                        "redemption_page_display_card_number": true,
                        "has_add_card_text": false,
                        "send_as_gift": false,
                        "applicableTag": false,
                        "has_maps": false
                    },
                    "titles": {
                        "homepage_pagetitle": "Giftcards | Kotak Mahindra Bank",
                        "homepage_description": "",
                        "homepage_keywords": "",
                        "header_title": "",
                        "modal_mobile_label": "Enter your mobile number registered with Kotak Mahindra Bank for OTP authentication",
                        "card_name": "Kotak White or White Reserve Credit Card",
                        "platform_title": "Kotak Mahindra Bank Offers and Benefits",
                        "concierge_name": "Kotak Mahindra Bank",
                        "login_modal_control": "Validate Your Mobile Number",
                        "card_name_extra_text": ""
                    },
                    "footer_additional_content": "<p>Disclaimer: Kotak Mahindra Bank Offers & Privileges is a platform for communication of personalized offers extended by Merchants to Kotak Mahindra Bank's Customers. Kotak Mahindra Bank is only communicating the offers extended by Merchants to its Customers and not Selling/Rendering any of these Products/Services. Kotak Mahindra Bank is neither guaranteeing nor making any representation. Kotak Mahindra Bank is not responsible for Sale/Quality/Features of the Products/Services under the offers.</p><p>Site is best viewed in Chrome V23+, Firefox V15+, Safari 6+ and IE11+ at 1024 x 768 pixels resolution, or above.</p>",
                    "content": {
                        "bin_error": {
                            "first4_last4": "Dear customer, please re-check the first 4 and last 4 card number you have entered",
                            "first6": "Dear customer, please re-check the first 6 digits of your card number you have entered",
                            "last4": "Dear customer, please re-check the last 4 digits of the card number you have entered"
                        },
                        "bin_invalid": {
                            "first4_last4": "Please enter your valid first 4 and last 4 digits of your debit card correctly. In case the issue persists, write to support@poshvine.com",
                            "first6": "Invalid Card",
                            "last4": "Dear customer, please re-check the last 4 digits of your card number you have entered"
                        },
                        "preset_error": "Dear Customer, please check if you have entered your bank-registered mobile number."
                    }

                };
        }
}
