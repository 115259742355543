import { Injectable, EventEmitter } from '@angular/core';
// import { HttpClient } from './http-client';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AESEncryptDecryptService } from 'src/app/authentication/encryption/AESEncryptDecryptService.service';
import { User } from './user.modal';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthenticationComponent } from './authentication.component';



@Injectable({ providedIn: 'root' })

export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public errorEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public reloadInit: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isAuthenticationComponentOpen: boolean = false;

  constructor(
    private http: HttpClient,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
    public matDialog: MatDialog
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

      environment.corePath = environment.corePathV2;
      environment.offerPath = environment.offerPathV2;

      this.errorEvent.subscribe((res) => {
        if (res && !this.isAuthenticationComponentOpen) {
          this.isAuthenticationComponentOpen = true;
          this.matDialog.open(AuthenticationComponent, {
            panelClass: 'authenticationModal',
            data: {
              callbackOnClose: true
            }
          }).afterClosed().subscribe((res) => {
            if(res) {
              location.reload();
            }
            this.isAuthenticationComponentOpen = false;
          })
        }
      })
  }

  public get currentUserValue(): any {
    return this.http.get<any>(environment.corePath + '/user')
    map(res => {
      return res;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
  }

  getCurrentUser(bin, bin_type) {
    return this.http.get<any>(environment.corePath + '/user').pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }


  validateExistingUser(mobile: string, calling_code: string) {
      return this.http.post<any>(environment.corePath + '/users/search', {mobile: mobile, calling_code: calling_code})
        .pipe(
          map(res => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
  }


  createUser(mobile: string, email: string, country_id: string = "default") {
    if (country_id == 'default') {
      return this.http.post<any>(environment.corePath + '/users', { mobile: mobile, email: email })
        .pipe(
          map(res => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    }
    else {

      return this.http.post<any>(environment.corePath + '/users', { mobile: mobile, email: email, country_id: country_id })
        .pipe(
          map(res => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    }

  }

  requestUserOtp(mobile: string, userId: string) {
    return this.http.post<any>(environment.corePath + '/users/' + userId + '/otps', { mobile: mobile, otp_type: "login" })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  validateUserOtp(userId: string, otp: string, otpType: string, mobileNum?: any) {
    return this.http.post<any>(environment.corePath + '/users/' + userId + '/otps/verify', { otp_type: otpType, code: otp })
      .pipe(
        map(res => {
          var user = {
            id: userId,
            token: res.token
          }

          // if (mobileNum) {
          //   user['mobile'] = mobileNum;
          // }
          if (user && user.token) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(res);
          }

          return user;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('card_verified');
    localStorage.removeItem('cardSelected');
    localStorage.removeItem('eligible_banners');
    localStorage.removeItem('locationData');
    localStorage.removeItem('currentUserCard');
    sessionStorage.removeItem('points');
    sessionStorage.removeItem('client');
    localStorage.removeItem('userCard');
    localStorage.removeItem('userCards');
    localStorage.removeItem('thebin');
    localStorage.removeItem('urlcode');
    localStorage.removeItem('expire_at');
    localStorage.removeItem('selectedCardType');
    const firstLoad = localStorage.getItem('firstLoad');
    const userCountry = localStorage.getItem('userCountry');
    const redirectData = localStorage.getItem('redirectData');

    // localStorage.clear();
    sessionStorage.clear();
    if(userCountry){
      localStorage.setItem("userCountry", userCountry);
    }
    if (firstLoad) {
      localStorage.setItem("firstLoad", firstLoad);
    }
    if (redirectData) {
      localStorage.setItem("redirectData", redirectData);
    }



    this.currentUserSubject.next(null);
  }

  deleteToken() {
    if (JSON.parse(localStorage.getItem('currentUser'))) {
      var currentUserId = JSON.parse(localStorage.getItem('currentUser')).id
    }
    return this.http.post<any>(environment.corePath + '/sessions/' + currentUserId, {})
      .pipe(
        map(res => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }
}
