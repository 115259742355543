import { appConstants } from 'src/app/app.constants';
import { GiftcardService } from 'src/app/pages/gift-cards/service/giftcard.service';
import { HostListener, Component, OnInit, EventEmitter, Input, Output, ViewChild, OnDestroy, Inject } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/config/api.service';
import { GlobalConstants } from 'src/app/config/global-variables';
import { Location } from '@angular/common';
import { debounceTime, takeUntil } from 'rxjs/operators'
import { Subject, Subscription, forkJoin, combineLatest, of } from 'rxjs';
import { AuthenticationComponent } from 'src/app/authentication/authentication.component';
import { DetectLocation } from 'src/app/shared/services/common/detect-location.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DOCUMENT } from '@angular/common';
import { PV_USER_SERVICE } from '@pv-frontend/pv-shared-services/pv-user-service';
import { PvUserServiceImpl } from 'src/services/user-service-impl.service';
import { TokenExpiredConfig } from '@pv-frontend/authentication';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  providers: [ApiService, GlobalConstants]
})
export class HeaderComponent implements OnInit, OnDestroy {
  datas: string;
  cartData;
  @Input() data: string[];
  @Input() points: any = '/-';
  @Input() redeemResponse: string[];
  @Output() rewardPoints = new EventEmitter<any>();
  @Output() categoryChanged = new EventEmitter<string>();
  @Output() allCategories = new EventEmitter<any>();
  @Output() reloadTemplate = new EventEmitter();
  @Output() cartResponse = new EventEmitter<object>();
  @Output() cartApiResponseStatus = new EventEmitter<boolean>();
  @Output() userCardDetails = new EventEmitter<any>();
  // @ViewChild(AuthenticationComponent) authC:AuthenticationComponent;
  sideMenuOpen = false;
  showAuthentication: any = false;
  selectedCat: any = '';
  isSearchDropdownOpen: any = false;
  merchantList: any = [];
  nearBymerchantList: any = [];
  showNull: any;
  searchInput: string = '';
  deviceInfo = null;
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktopDevice: boolean = false;
  isLoggedIn: boolean = false;
  offerCategories: any = [];
  modalOpen: any = false;
  openMenu: any = false;
  openMenuMob: any = false;
  modalOpenMob: any = false;
  userCards: any = [];
  temp2SearchOpen = false;
  public templateNum: number = 1;
  filteredCategories: any;
  categories: any;
  currentUserCard: any;
  private subject: Subject<string> = new Subject();
  searching: boolean = false;
  searchSubscription: Subscription;
  giftcardList = [];
  searchType = 'All';
  urlcode: any;
  categoryList = []
  isGiftCardRoute= false;
  cartLength = 0;
  cartSub: Subscription;
  routeSub: Subscription;
  private destroy$: Subject<void> = new Subject<void>();
  public showTokenExpiredModal: boolean = false;
  public tokenExpiredModuleConfig?: Partial<TokenExpiredConfig> = {
    drawer: {
      config: {
        dismissOnBackdropClick: true
      }
    },
    config: {
      title: 'Your login token has expired',
      tokenExpiredIcon: 'hour-glass',
      description: 'Please re-authenticate to continue',
      reAuthenticateCtaButton: {
        isRedirectedFromApp: false,
        label: 'Login'
      },
    },
    style: {
      tokenExpiredIcon: {
        width: '60px',
        height: 'auto',
        fill: 'var(--primary-color)',
      },
      reAuthenticateCtaButton: {
        width: '160px',
        height: '50px',
        color: "#ffffff"
      },
    },
  };

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth > 1024) {
      this.isDesktopDevice = true;
      this.isTablet = false;
      this.isMobile = false;
      // this.ngOnInit();
    }
    else if (window.innerWidth > 768) {
      this.isDesktopDevice = false;
      this.isTablet = true;
      this.isMobile = false;
      // this.ngOnInit();
    }
    else {
      this.isDesktopDevice = false;
      this.isTablet = false;
      this.isMobile = true;
    }
  }
  constructor(
    private router: Router,
    private _apiService: ApiService,
    private authenticationService: AuthenticationService,
    public GlobalConstants: GlobalConstants,
    private location: Location,
    private route: ActivatedRoute,
    // private _detectLocation: DetectLocation,
    private giftcardService: GiftcardService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    @Inject(PV_USER_SERVICE) private pvUserService: PvUserServiceImpl,
  ) {
    this.subscribeToLogin();
    this.onResize();

    this.pvUserService.tokenExpiredObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (isExpired: boolean) => {
          if (isExpired && !this.showTokenExpiredModal) {
            this.pvUserService.logoutUser();
            this.showTokenExpiredModal = isExpired;
          }
        },
      });
  }

  getCards() {
    // if (localStorage.getItem('userCards')) {
    //   this.userCards = JSON.parse(localStorage.getItem('userCards'));
    //   this.markCurrentCard();
    // }
    // else{
      this._apiService.getCards().subscribe(
        (res: any) => {
          this.userCards = res.data;
          this.userCards.forEach(obj => {
            obj.attributes.display_name = obj.attributes.display_name.replace('Mahindra Bank', '');
          });
          localStorage.setItem("userCards", JSON.stringify(this.userCards));
          this.markCurrentCard();

        },
        (err) => {
        }
      );
    // }
    if (sessionStorage.getItem('points')) {
      this.points = JSON.parse(sessionStorage.getItem('points'));
      this.rewardPoints.emit(this.points);
    }
    else {
      this._apiService.getCardsWithPoints().subscribe(
        (res: any) => {
          this.points = res.data.attributes.credits;
          this.rewardPoints.emit(this.points);
          sessionStorage.setItem("points", JSON.stringify(this.points));
        },
        (err) => {

          this._apiService.getCardsWithPoints().subscribe(
            (res: any) => {
              this.points = res.data.attributes.credits;
              sessionStorage.setItem("points", JSON.stringify(this.points));
              this.rewardPoints.emit(this.points);

            },
            (err) => {

            }
          );
        }
      );
    }

  }


  markCurrentCard() {
    this.userCards.forEach((x) => {
      if (this.currentUserCard != 'undefined' && this.currentUserCard && this.currentUserCard.id && (x.id == this.currentUserCard.id)) {
        x['currentCard'] = true;
      } else {
        x['currentCard'] = false;
      }
    });
  }

  ngOnInit() {
    if(!(this.document.location.pathname.includes('sso_login'))){
      if (localStorage.getItem('userCard')) {
        let usercard = JSON.parse(localStorage.getItem('userCard'));
        this.currentUserCard = usercard;
      }
      this.authenticationService.currentUser.subscribe(res=> {
        if(res) {
          this.isLoggedIn = true;
          this.getUserDetails();
          this.getCards();
          this.callGiftcardsInCart()
        } else {
          this.isLoggedIn = false;
        }
      })
      this.subject.pipe(
        debounceTime(300)
      ).subscribe(searchTextValue => {
        // this.populateMerchantList(searchTextValue);
      });
      // this._detectLocation.getInternationalGeoLocation()
      this.routeSub = this.giftcardService.giftCardRoute$.subscribe(res => this.isGiftCardRoute = res)
      this.cartSub = this.giftcardService.cartItems$.subscribe(res => {
        this.cartLength = res?.length || 0;
        console.log('cart length', res)});
      // this.cartLength = res?.length || 0
    }
  }
  callGiftcardsInCart() {
    this.giftcardService.getGiftcardsInCart().then((res: any) => {
      this.cartLength = res.data?.length;
    });
  }

  reloadInit() {
    this.ngOnInit();
    this.reloadTemplate.emit();
  }

  ngDoCheck() {
    if (localStorage.getItem("currentUser")) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
  }

  debouncedPopulateMerchantList(searchTextValue: any) {
    // this.subject.next(searchTextValue);
    this.searching = true;
    this.getSearch();
  }

  getSearch() {
    this.merchantList = [];
    this.giftcardList = [];
    this.categoryList = [];
    // this.membershipList = [];
    if (this.searchSubscription) {
      this.searching = false;
      this.searchSubscription.unsubscribe();
    }
    if (this.searchInput && this.searchInput.length > 2) {
      this.searching = true;
      this.searchSubscription = combineLatest([
        // this._apiService.getMerchants({ name: this.searchInput }),
        this.giftcardService.searchGiftcards(this.searchInput),
        this.searchCategoryName(this.searchInput)
        // this._apiService.getMerchants({ name: this.searchInput, benefit_type: 'memberships' })
      ]).subscribe((response: any) => {
        // this.merchantList = response[0].data;
        this.giftcardList = response[0].data;
        this.categoryList = response[1]
        // this.membershipList = response[2].data;
        this.searching = false;
      });
    }
  }

  searchCategoryName(searchVal) {
    const filteredCategoryList = appConstants.apps.filter((app) => {
      return app.name.toLowerCase().includes(searchVal.toLowerCase());
    });

    return of(filteredCategoryList);
  }


  backClicked() {
    this.location.back();
  }

  subscribeToLogin(): void {
    this.pvUserService.loginModalObservable$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (showLoginModal: boolean) => {
        showLoginModal && this.login();
      }
    })
  }

  login() {

    // this.authC.restartLogin();
    this.showTokenExpiredModal = false;
    if(!this.authenticationService.isAuthenticationComponentOpen) {
      this.authenticationService.isAuthenticationComponentOpen = true;
      this.dialog.open(AuthenticationComponent, {
        panelClass: 'authenticationModal',
        data: {
          callbackOnClose: false
        }
      }).afterClosed().subscribe((res) => {
        this.authenticationService.isAuthenticationComponentOpen = false;
      });
    }
  }

  hideAuthentication() {
    this.showAuthentication = false;
  }

  logout() {
    this.authenticationService.deleteToken().subscribe((res) => {
      this.authenticationService.logout();
      this.router.navigate(['/']);
      window.location.href = "/";
    }, (error) => {
      this.authenticationService.logout();
      this.router.navigate(['/']);
      window.location.href = "/";
    });
  }
  navigateCategory(categoryName) {
    this.isSearchDropdownOpen = false;
    this.router.navigate(['offer-list', categoryName]);
  }
  navigateToDetails(mer_id, mer_name) {
    this.isSearchDropdownOpen = false;
    this.router.navigate(['offer-details', mer_id, 'all', mer_name.split(' ').join('-').split(',').join('').split('(').join('').split(')').join('').toLowerCase()]);
  }

  headerTimeout: any;
  ngOnChanges(change) {
    if (this.data) {

    }
    else {
      this.modalOpen = false;
      this.headerTimeout = setTimeout(() => {
        if ((change.showProfileDropDown && change.showProfileDropDown.previousValue == false) || (change.showProfileDropDown && change.showProfileDropDown.previousValue == null)) {
          this.openMenu = change.showProfileDropDown.currentValue;
        }
        clearTimeout(this.headerTimeout);
      }, 200);
    }


  }

  setCurrentUserCards(card) {
    // localStorage.setItem("userCard", JSON.stringify(card));
    this.validateCard(card.attributes.bin, card.attributes.bin_type);
    // window.location.reload();
  }

  validateCard(bin, bin_type) {
    this._apiService.validateCard(bin, bin_type).subscribe(res => {
      if (res && res.data && res.data.attributes.bin == bin && res.data.attributes.status == 'bin_verified') {
        let current_user = JSON.parse(localStorage.getItem("currentUser"));
        current_user.token = res.data.attributes.session_token;
        localStorage.setItem('currentUser', JSON.stringify(current_user));
        localStorage.setItem('card_verified', "true");
        localStorage.setItem('userCard', JSON.stringify(res.data));

        this.markCurrentCard();

        if (localStorage.getItem('userCard')) {
          let usercard = JSON.parse(localStorage.getItem('userCard'));
          this.currentUserCard = usercard;
        }
        // location.reload();
        location.href = '';
      }
      else {
        localStorage.setItem('card_verified', "false");
      }
    })
  }


  currentTag: any = 'national';
  settag(tag) {
    this.currentTag = tag;
    setTimeout(() => {
      this.isSearchDropdownOpen = true;
    }, 200)
  }

  switchCategory(selectedCategory) {
    this.categoryChanged.emit(selectedCategory);
  }

  acceptRules() { };
  closeTab() { };
  recievedApiStatus(res){}
  getGiftcardsInCart(res){}

  emitGiftres(res) {
    this.cartResponse.emit(res);
  }
  cartApiStatus(res) {
    this.cartApiResponseStatus.emit(res);
  }
  userDetails: any;
  getUserDetails() {
    this._apiService.getUserDetails().subscribe((res) => {
      if (res) {
        localStorage.setItem('userDetails', JSON.stringify(res));
        this.userDetails = res;
      }
    })
  }
  toLocal(num) {
    if (num == 0 || num == '/-') {
      return num;
    }
    num = parseInt(num);
    return Number(num).toLocaleString('en-IN')
  }


  changeSearchType(type) {
    this.searchType = type;
  }

  ngOnDestroy(): void {
    this.cartSub.unsubscribe();
    this.routeSub.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
