import { Environment } from "./type";

export const environment: Environment = {
  production: true,
  corePath: "https://api-customer.poshvine.com/core_service/v1",
  corePathV2: "https://api-customer.poshvine.com/cs/v1",
  offerPath: "https://api-customer.poshvine.com/offer_service/v1",
  giftPathV2: "https://offers-api-customer.poshvine.com/offer_service/v2",
  offerPathV2: "https://api-customer.poshvine.com/ps/v1",
  apiUrlPaV1: "https://api-customer.poshvine.com/pa/v1/",
  apiUrlLsV1: "https://api-customer.poshvine.com/ls/v1/",
  baseUrl: "https://api-customer.poshvine.com/",
  apiUrl: "https://rewardshub-stage-api-customer.poshvine.com/api/v1/",
  stagingUrl: "https://rewardshub-stage-api-customer.poshvine.com/",
  perksUrl: "https://sandbox-api-customer.poshvine.com/ps/rewardshub/",
  rewardsHubApiUrl: "https://rewards-api-customer.poshvine.com/api/v1/",
  analytics: "https://assets.adobedtm.com/8e068bb67c90/07a78f6404f1/launch-5e1bff55b076.min.js",
  gmApiKey: "AIzaSyCX4nc8CaDOF9TIWqmKQo48SLo05LOJf3Y",
  secretKey: "000102030405060708090a0b0c0d0e0f",
  ipstackKey: "022375a36968cebce87e10e1c6447ef6",
  clientId: "0dabaa48-646f-411b-a4d5-a3d422c61c63",
  projectId: "d5dacbae-f0e4-4594-adea-78ce733a3b42",
  whiteReserveProjectId: "76076f3d-28d2-43f0-870f-ff92f1a255bc",
  apiPerksServiceUrl: "https://api-customer.poshvine.com/ps/v1",
  apiPerksServiceUrlV2: "https://api-customer.poshvine.com/ps/v2",
  apiClientsServiceUrl: "https://api-customer.poshvine.com/cs/v1",
  apiBookingServiceUrl: "https://api-customer.poshvine.com/bs/v1/",
  apiPointsServiceUrl: "https://api-customer.poshvine.com/pts/v1",
  sentryDsn: "https://7668945428aa2b0b85fae8dc58bdd66e@o515678.ingest.us.sentry.io/4507299716005888",
  SENTRY_ORG: "rzp",
  SENTRY_PROJECT: "kotak-app",
  envName: "production",
  projectLink: "https://www.kotakbankwhite.com/",
};
